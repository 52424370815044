<script>
  import { mapActions, mapGetters } from "vuex";
  import SocketMySpace from "../../../socket/myspace";

  export default {
    components: {
      MonthEmployeeCard: () => import("@/components/MonthEmployee/MonthEmployeeCard.vue"),
      Modal: () => import("@/components/modals/ModalPost.vue"),
      MonthEmployeeCarousel: () => import("@/components/MonthEmployee/MonthEmployeeCarousel.vue"),
      UsersListVisor: () => import("../../../components/widgets/Modals/UsersListVisor.vue"),
    },
    data() {
      return {
        year: null,
        userTemporal: {},
        showModalViewers: false,
        showModalLikes: false,
        usersList: [],
        detailVisor: "",
      };
    },
    computed: {
      ...mapGetters("MonthEmployee", ["getEmployeeByYearMonth", "getMonthEmployees"]),
      showModal() {
        return !!this.userTemporal?._id;
      },
      employeesWithPeriod() {
        return this.getMonthEmployees.map((employee) => {
          const period = this.formatPeriod(employee);
          const yearsInCompany = this.calculateYearDifference(employee.user.date);

          return { ...employee, period, yearsInCompany };
        });
      },
    },
    methods: {
      ...mapActions("MonthEmployee", ["fetchMonthEmployees"]),
      setPeriod(month) {
        const formattedMonth = month.toString().padStart(2, "0");
        return `${this.year}${formattedMonth}`;
      },
      formatPeriod({ year, month }) {
        try {
          if (!Number.isInteger(year) || !Number.isInteger(month) || month < 1 || month > 12) {
            throw new Error("Fecha no válida");
          }

          const mainLang = "es-CO";
          const date = new Date(year, month - 1, 1);

          if (isNaN(date.getTime())) {
            throw new Error("Fecha no válida");
          }

          const formattedDate = date.toLocaleDateString(mainLang, { month: "long" });

          return formattedDate;
        } catch (error) {
          console.log(error.message, year, month);
        }
      },
      calculateYearDifference(specificDate) {
        const startDate = new Date(specificDate);
        const today = new Date();
        const yearsDiff = today.getFullYear() - startDate.getFullYear();

        if (yearsDiff) {
          const yearString = this.requirePlural(yearsDiff) ? "años" : "año";

          return `${yearsDiff} ${yearString} de antigüedad`;
        }

        const monthsDiff = today.getMonth() - startDate.getMonth();
        const monthString = this.requirePlural(monthsDiff) ? "meses" : "mes";

        return `${monthsDiff} ${monthString} de antigüedad`;
      },
      requirePlural(num) {
        return num > 1;
      },
      getMonthEmployeeByPeriod(month) {
        const defaultMonth = {
          year: this.year,
          month,
        };
        const employee = this.getEmployeeByYearMonth({ year: this.year, month });
        const defUser = employee || defaultMonth;
        const period = this.formatPeriod(defUser);

        defUser.period = period;

        if (employee) {
          const yearsInCompany = this.calculateYearDifference(employee.user.date);
          defUser.yearsInCompany = yearsInCompany;
        }

        return defUser;
      },
      changeStateModal(employee) {
        if (!this.showModal && !employee?._id) {
          return;
        }

        this.userTemporal = employee || {};

        if (this.showModal) {
          const socket = SocketMySpace.getSocket();
          const optsScroll = { top: 0, behavior: "smooth" };

          socket.emit("newViewItem", { item: employee._id, component: "MonthEmployee" });
          window.scroll(optsScroll);
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "";
        }
      },
      openVisorByReaction(reaction, data) {
        const dicReaction = {
          likes: () => this.openLikes(data),
          views: () => this.openViews(data),
          claps: () => this.openClaps(data),
        };

        dicReaction?.[reaction]?.();
      },
      openVisor(users, text) {
        this.usersList = users;
        this.detailVisor = text;
      },
      openLikes(dataLikers) {
        const text = "Los usuarios que dieron like:";
        const likers = dataLikers.map(({ user }) => user);

        this.openVisor(likers, text);
      },
      openViews(dataViewers) {
        const text = "Los usuarios que han visto:";
        const viewers = dataViewers.map(({ user, views }) => ({ ...user, views }));

        this.openVisor(viewers, text);
      },
      openClaps(claps) {
        const text = "Los usuarios que han aplaudido:";
        const clapers = claps.map(({ user, claps }) => {
          if (this.$user.role === "superadmin") {
            user.views = claps;
          }

          return user;
        });

        this.openVisor(clapers, text);
      },
      resetVisor() {
        this.usersList = [];
        this.detailVisor = "";
        this.showModalViewers = false;
        this.showModalLikes = false;
      },
    },
    async beforeMount() {
      const currentDate = new Date();
      this.year = currentDate.getFullYear();

      await this.fetchMonthEmployees({ year: this.year });
    },
    beforeDestroy() {
      document.body.style.overflow = "";
    },
  };
</script>

<template>
  <main class="monthEmployee">
    <h1 class="monthEmployee__title">Empleado del Mes</h1>
    <div class="monthEmployee__container">
      <div class="monthEmployee__carrousel">
        <MonthEmployeeCarousel @openModal="changeStateModal" :employees="employeesWithPeriod" :openVisorByReaction="openVisorByReaction" />
      </div>
      <div class="monthEmployee__cards">
        <MonthEmployeeCard v-for="month in 12" :employee="getMonthEmployeeByPeriod(month)" :key="month" :openVisorByReaction="openVisorByReaction" @openModal="changeStateModal" />
      </div>
    </div>
    <Modal :employee="userTemporal" :openVisorByReaction="openVisorByReaction" class="monthEmployee__modal" @close="changeStateModal" v-if="showModal" />
    <UsersListVisor :users="usersList" :detail="detailVisor" :closeModal="resetVisor" v-if="usersList.length" />
  </main>
</template>

<style lang="scss">
  .monthEmployee {
    @include Flex(column, flex-start, flex-start);
    width: 100%;
    padding-bottom: 45px;
    gap: $space-16;
    max-width: 1440px;
    &__title {
      font-family: $newFont_bold;
      font-size: 32px;
    }
    &__modal {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 200;
    }
    &__container {
      @include Flex(column, flex-start);
      width: 100%;
      height: 100%;
      gap: $space-16;
    }
    &__carrousel {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      border: 1px solid transparent;
    }
    &__cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      gap: 16px;
    }
    @media screen and (min-width: 600px) {
      &__cards {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media screen and (min-width: 768px) {
      height: calc(100vh - 90px);
    }
    @media screen and (min-width: 1024px) {
      padding-bottom: 0px;
      &__container {
        flex-direction: row;
        height: calc(100% - 42px);
      }
      &__carrousel {
        height: 100%;
        width: 350px;
      }
      &__cards {
        overflow-y: auto;
        height: 100%;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        padding-right: 10px;
      }
    }
    @media screen and (min-width: 1440px) {
      &__container {
        padding: 0 40px;
      }
      &__cards {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
</style>
